<template>
    <div class="common-block sidebar custom-scroll" :class="{'white-theme': whiteTheme, inverse: inverse, 'deputies': deputies, 'background-participant': !deputies, 'yellow-color': !deputies, 'blue-background': blueBackground}">
        <header>
            <router-link to="/" class="header-logo">
                <img src="../assets/images/logo-header.svg" alt="logo" v-if="whiteTheme"/>
                <img src="../assets/images/logo-inverce.svg" alt="logo" v-else-if="inverse"/>
                <img src="../assets/images/logo.svg" alt="logo" v-else-if="blueTheme"/>
                <img src="../assets/images/light.svg" alt="" v-else-if="!deputies">
                <img src="../assets/images/logo.svg" alt="logo" v-else/>
            </router-link>
            <Breadcrumb :darkTheme="!deputies" :items="breadcrumbs" :visible-main-page="visibleMainPage" v-if="breadcrumbs.length" class="breadcrumb"/>
        </header>
        <div class="body" :class="{'body--dark' : deputies}">
            <h2 class="title">{{title}}</h2>
            <div class="slot">
                <slot></slot>
            </div>
        </div>
        <footer>
            <button @click="clickBack" class="btn-back" v-if="visibleBackBtn">
                <span class="btn-back_icon">
                    <img src="../assets/images/btn-back.svg" alt="back"/>
                </span>
                Повернутись
            </button>
        </footer>
        <ActionPanel @searchModal="visibleSearch = !visibleSearch"/>
        <SearchModal :visible="visibleSearch" @close="visibleSearch = !visibleSearch"/>
    </div>
</template>

<script>
import Breadcrumb from "./Breadcrumb";
import ActionPanel from "../components/ActionPanel";
import SearchModal from "@/components/SearchModal";
import Input from "./ui/Input";

export default {
    name: "CommonBlock",
    components: {
        Input,
        Breadcrumb,
        ActionPanel,
        SearchModal
    },
    data() {
        return {
            visibleSearch: false,
            deputies: this.$store.getters.identity.is_deputy,
            // deputies: false,
            visibleAdmin: null
        }
    },
    props: {
        title: {
            type: String,
            required: false
        },
        visibleBackBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        visibleMainPage: {
            type: Boolean,
            required: false,
            default: true
        },
        cancelBackBtnEventPassed: {
            type: Boolean,
            required: false,
            default: false
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        whiteTheme: {
            type: Boolean,
            required: false
        },
        inverse: {
            type: Boolean,
            default: false
        },
        darkTheme: {
            type: Boolean,
            required: false,
            default: false
        },
        blueTheme:{
            type: Boolean,
            required: false,
            default: false
        },
        blueBackground:{
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        clickBack() {
            if(this.cancelBackBtnEventPassed){
                this.$emit('clickBack')
            }else{
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style lang="less" scoped>
.image {
    margin-top: 60px;
    margin-bottom: 50px;
}

.common-block{
    overflow-x: hidden;
    min-height: 100vh;
    width: 33.33%;
    //height: 100%;
    height: auto;
    max-width: 640px;
    background: url(../assets/images/back.jpg) no-repeat center center;
    background-size: cover;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index: 2;
    max-height: 100vh;
    @media all and(max-width: 1600px) {
        padding: 80px 50px;
    }
    @media all and(max-height: 750px) {
        padding-bottom: 40px;
    }
    @media all and(max-width: 1200px) {
        width: 45%;
    }
    @media all and(max-width: 1100px) {
        width: 35%;
        padding: 70px 20px 30px 45px;
    }
    @media all and(max-width: 992px) {
        height: auto;
        width: 100%;
        padding: 20px 40px;
        display: block;
        max-width: 100%;
        overflow: visible;
        position: relative;
        .base-text{
            display: none;
        }
        h2{
            font-size: 26px;
        }
    }
    @media all and(max-width: 480px)  {
        padding: 20px 40px;
        h2 {
            font-size: 22px;
            line-height: 30px;
        }
    }
    @media all and(max-width: 375px)  {
        h2 {
            font-size: 20px;
        }
    }
    @media all and(max-width: 345px)  {
        padding: 20px 25px;
    }
    .body{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    &.inverse {
        background: #ffca24 !important;

        .label {
            color: #1a2744;
        }
        .description {
            color: #1a2744;
            font-weight: 700;
        }

        .btn-back {
            color: #1a2744;

            &_icon {
                background: #1a2744;
            }
        }

        .slot {
            margin: 0;
        }
    }
}

.common-block.background-participant{
    background-image: url("../assets/images/back-participant.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.common-block.yellow-color .description{
    font-weight: 600;
}

.yellow-color{
    background: none;
    background-color: #ffca24 !important;
    font-weight: 600;

    .description{
        color: #1A2744!important;
        font-weight: 600;
    }
    .title{
        color: #1a2744;
        font-weight: 600;
    }
    .participant-container .user-main .user-main-item .title{
        font-weight: 600;
    }
    .btn-back{
      font-family: @secondFontFamily;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.33;
      color: #fff;
    }
    .btn-back_icon{
      width: 40px;
      height: 40px;
      padding: 12px 16px 11px 14px;
      background-color: #1a2744;
    }
    .category-text{
        color: #192645;
        font-weight: 600;
    }
}
.breadcrumb{
    margin-bottom: 40px;
    @media all and(max-width: 1400px) {
        margin-bottom: 15px;
    }
}
.event-common,
.calendar-common {
    .breadcrumb {
        @media all and(max-width: 992px) {
            display: block;
        }
    }
}

.title{
    font: 700 42px/1.52 @secondFontFamily;
    letter-spacing: -0.29px;
    color: #fff;
    @media all and(max-width: 1600px) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
    }
    @media all and(max-width: 1440px) {
        font-size: 35px;
        line-height: 35px;
    }
    @media all and(max-width: 1440px) {
        font-size: 28px;
    }
    @media all and(max-width: 950px) {
        margin-bottom: 10px;
        line-height: 40px;
    }
}
.slot{
    flex: 1 1 auto;
    margin: 70px 0;
    position: relative;

    @media all and(max-width: 1100px) {
        margin: 15px 0 0;
    }
}
.image {
    width: 100%;
}
.btn-back {
    display: inline-flex;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: .18px;
    color: @yellowColor;
    text-decoration: none;
    position: relative;
    z-index: 100;
    @media all and(max-width: 992px) {
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 21px;
        transform: rotate(180deg);
        transform-origin: center center;
        font-size: 0;
        &:before, &:after{
            content: '';
            display: block;
            position: absolute;
            border-color: #fff;
            background-color: #fff;
            border-radius: 5px;
            width: 15px;
            height: 3px;
            right: -2px;
        }
        &::after {
            top: 5px;
            transform: rotate(45deg);
            border-radius: 4px;
        }
         &::before {
            top: 14.3px;
            transform: rotate(-45deg);
        }
        display: none;
    }
    &_icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: @yellowColor;
        box-shadow: 0 24px 24px -14px fade(@yellowColor, 35%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }
    @media all and(max-width: 950px) {
        &_icon{
            box-shadow: none;
            height: 3px;
            width: 22px;
            background-color: #fff;
            border-radius: 4px;
            margin: 0;
            position: relative;
            top: 0;
        }
        img{
            display: none;
        }
    }
}
.header-logo {
    width: 253px;
    height: 51px;
    margin-bottom: 20px;
    display: inline-block;
    @media all and(max-width: 1024px) {
        width: 200px;
    }
    @media all and(max-width: 950px) {
        height: 40px;
        width: 62px;
        overflow: hidden;
        margin-bottom: 5px;
        background: transparent url("../assets/images/logo.svg") left center no-repeat;
        background-size: auto 100%;
    }
    img{
        width: 100%;
        height: auto;
        @media all and(max-width: 950px) {
            height: 100%;
            width: auto;
            display: none;
        }
    }
}
.blue-background{
    background: url(../assets/images/back.jpg) no-repeat center center;
    .title{
        color: white;
    }
    .btn-back{
        color: #ffc900;
    }
    .breadcrumb{
            a{
                color: #ffc900;
                background-color: red;
            }
    }
}
.yellow-color{
    .slot .img {
        display: block;
        height: auto;

        img{
            width: 68.5%;
        }
    }
    @media (max-width: 950px){
        .header-logo{
            width: 100%;
        }
        .header-logo img{
            display: block;
        }
    }
    .filter-title{
        color: #1a2744;
    }
    .input-field input::placeholder{
        color: #1a2744;
    }
    ::v-deep .vs__selected-options .vs__search, ::v-deep .vs__selected-options .vs__selected{
        color: #1a2744;
        font-weight: 500;
    }
    ::v-deep .vs--open .vs__dropdown-toggle{
        background-color: white;
    }
    .input-field input{
        font-weight: 500;
        color: #1a2744;
    }
    ::v-deep .custom-select-v2 .vs__open-indicator:after{
        background: url('../assets/images/arrows_light.svg') no-repeat center;
    }
    ::v-deep .vs--open .vs__actions .vs__open-indicator:after{
        background: url("../assets/images/arrows_dark.svg") no-repeat center;
    }
    .filter-actions .custom-button{
        background-color: #1a2744;
    }

}

.blue-background{
    .breadcrumb{
        ::v-deep{
            a{
                color: #ffc900;
            }
            li:first-child a{
                color: #ffc900;
            }
            li:last-child span {
                color: white;
            }
            li:last-child:before {
                background: white;
            }
            li:not(:first-child):before{
                background-color: #ffc900;
            }
            li:last-child:before{
                background-color: white;
            }
        }
    }
}
@media (max-width: 992px) {
    .common-block{
        min-height: auto;
    }
}

</style>


<style lang="scss">
    .common-block.sidebar.yellow-color {
        .info-field {
            .label {
                color: #1a2744;
            }
        }
    }
</style>
