<template>
    <ul :class="{dark: darkTheme, 'party-member': !deputies}">
        <li v-if="visibleMainPage">
            <router-link to="/">Головна</router-link>
        </li>
        <li v-for="item in items" :key="item.id">
            <router-link :to="item.link" v-if="item.link">{{item.name}}</router-link>
            <span v-else>{{item.name}}</span>
        </li>
    </ul>
</template>

<script>
export default {
    name: "Breadcrumb",
    data () {
        return {
            deputies: this.$store.getters.identity.is_deputy,
        }
    },
    props: {
        visibleMainPage: {
            type: Boolean,
            require: false,
            default: true
        },
        items: {
            type: Array,
            require: true
        },
        darkTheme: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped lang="less">
ul {
    list-style: none;
    @media all and(max-width: 1024px) {
        margin-bottom: 25px;
    }
    li {
        display: inline;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: #fff;
        position: relative;
        
        &:not(:first-child){
            margin-left: 19px;
            
            &::after {
                content: '';
                position: absolute;
                top: 1px;
                left: -10px;
                display: inline-block;
                width: 1px;
                height: 15px;
                background: #fff;
                bottom: 1px;
            }
        }

    }
    a{
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.112px;
        color: @yellowColor;
        text-decoration: none;
        position: relative;
    }
    &.dark{
        li:not(:first-child){
            &:before {
                background: @blueColor;
            }
        }
        li{
            a {
                color: @blueColor;  
            }
            color: white;
        }

        &:not(.party-member) {
            li{
                a {
                    color: @blueColor;  
                }
                color: @blueColor;

                &::after {
                    background: @blueColor;
                }
            }
        }
    }
}

.inverse {
    ul {
        li {

            a {
                color: @blueColor;
            }
        }
    }
}
.party-member {
    li:not(:first-child){
        &:before {
            background: #fff;
            //background: @blueColor;
        }
    }
    li:first-child a{
        //color: @blueColor;
    }
}
</style>
