<template>
    <div class="action-panel">
        <button @click="$emit('searchModal')">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.816 10.4945L15.4593 13.1331C16.1802 13.9326 16.1802 15.132 15.4593 15.9315C14.6583 16.7311 13.4568 16.7311 12.6558 15.9315L10.0125 13.293C9.05131 13.7727 7.92991 14.0925 6.80851 14.0925C3.0438 14.0925 0 11.0542 0 7.29626C0 3.53833 3.0438 0.5 6.80851 0.5C10.5732 0.5 13.617 3.53833 13.617 7.29626C13.617 8.41565 13.2966 9.53503 12.816 10.4945ZM1.602 7.29626C1.602 10.1747 3.92491 12.4934 6.80851 12.4934C9.69212 12.4934 12.015 10.1747 12.015 7.29626C12.015 4.41785 9.69212 2.09912 6.80851 2.09912C3.92491 2.09912 1.602 4.41785 1.602 7.29626Z" fill="white"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.816 10.4945L15.4593 13.1331C16.1802 13.9326 16.1802 15.132 15.4593 15.9315C14.6583 16.7311 13.4568 16.7311 12.6558 15.9315L10.0125 13.293C9.05131 13.7727 7.92991 14.0925 6.80851 14.0925C3.0438 14.0925 0 11.0542 0 7.29626C0 3.53833 3.0438 0.5 6.80851 0.5C10.5732 0.5 13.617 3.53833 13.617 7.29626C13.617 8.41565 13.2966 9.53503 12.816 10.4945ZM1.602 7.29626C1.602 10.1747 3.92491 12.4934 6.80851 12.4934C9.69212 12.4934 12.015 10.1747 12.015 7.29626C12.015 4.41785 9.69212 2.09912 6.80851 2.09912C3.92491 2.09912 1.602 4.41785 1.602 7.29626Z" fill="white"/>
                </mask>
                <g mask="url(#mask0)">
                <rect y="0.5" width="16" height="16" />
                </g>
            </svg>
        </button>
        <router-link
            to="/profile"
            class="to-cabinet"
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0186 10.3125C13.2288 9.47981 12.2799 8.87406 11.2805 8.54416C12.2762 7.64609 12.9032 6.34659 12.9032 4.90322C12.9032 2.19959 10.7036 0 8 0C5.29638 0 3.09678 2.19959 3.09678 4.90322C3.09678 6.34656 3.72378 7.64609 4.71953 8.54419C3.72003 8.87409 2.77116 9.47981 1.98137 10.3125C0.703687 11.6596 0 13.4045 0 15.2258V16H16V15.2258C16 13.4045 15.2963 11.6596 14.0186 10.3125ZM4.64516 4.90322C4.64516 3.05338 6.15013 1.54838 8 1.54838C9.84984 1.54838 11.3548 3.05334 11.3548 4.90322C11.3548 6.75306 9.84988 8.25806 8 8.25806C6.15016 8.25806 4.64516 6.75309 4.64516 4.90322ZM6.45159 9.80647C4.33406 9.80647 1.98719 11.7463 1.60287 14.4516H14.3971C14.0128 11.7463 11.6658 9.80647 9.54838 9.80647H6.45159Z" />
            </svg>
        </router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .action-panel {
        display: none;
        position: absolute;
        top: 32px;
        right: 20px;
        button,
        .to-cabinet  {
            width: 16px;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in;
        }
        svg {
            fill: #fff;
        }
        @media screen and (max-width: 992px) {
            display: flex;
            align-items: center;
        }
    }
    .to-cabinet {
        margin-left: 30px;
    }
</style>
